import { getCookie } from "../functions/cookie";
import { request } from "../functions/request";

export function getUserInfoRequest() {
  return request(`/account/`, {
    headers: {
      Authorization: getCookie("accessToken"),
    },
  });
}

export function updateUserInfoRequest(data: any) {
  return request(`/account/index.php`, {
    method: "PATCH",
    headers: {
      Authorization: getCookie("accessToken"),
    },
    body: JSON.stringify(data),
  });
}

export function logoutUserRequest() {
  return request(`/auth/logout.php`, {
    headers: {
      Authorization: getCookie("refreshToken"),
    },
  });
}

export function favouriteRequest(id: number) {
  return request(`/account/update_favorites/index.php?id=${id}`, {
    method: "PATCH",
    headers: {
      Authorization: getCookie("accessToken"),
    },
  });
}

export function updateCartRequest(id: number, quantity?: number) {
  return request(
    `/account/basket/index.php?id=${id}${`&quantity=${quantity}`}`,
    {
      method: "PATCH",
      headers: {
        Authorization: getCookie("accessToken"),
      },
    }
  );
}

export function updateUnauthCartRequest(id: number, quantity?: number) {
  return request(
    `/account/virtual_basket/index.php?id=${id}${`&quantity=${quantity}`}`,
    {
      method: "PATCH",
      headers: {
        Authorization: getCookie("accessToken"),
      },
    }
  );
}

export function getUserOrdersRequest(page?: number) {
  return request(`/account/orders/${page ? `?page=${page}` : ``}`, {
    headers: {
      Authorization: getCookie("accessToken"),
    },
  });
}

export function getUserOrderId(id: string) {
  return request(`/account/orders/?id=${id}`, {
    headers: {
      Authorization: getCookie("accessToken"),
    },
  });
}

export function getUserAdsRequest() {
  return request(`/account/announcement/?action=my`, {
    method: "GET",
    headers: {
      Authorization: getCookie("accessToken"),
    },
  });
}

export async function getUserAdDetails(id: string | number) {
  return await request(`/account/announcement/?id=${id}`, {
    method: "GET",
    headers: {
      Authorization: getCookie("accessToken"),
    },
  });
}

export function removeUserAdRequest(id: number) {
  return request(`/account/announcement/index.php?id=${id}`, {
    method: "DELETE",
    headers: {
      Authorization: getCookie("accessToken"),
    },
  });
}

export function offUserAdRequest(id: number) {
  return request(
    `/account/announcement/index.php?id=${id}&action=notpublished`,
    {
      method: "PATCH",
      headers: {
        Authorization: getCookie("accessToken"),
      },
    }
  );
}

export function onUserAdRequest(id: number) {
  return request(`/account/announcement/index.php?id=${id}&action=moderate`, {
    method: "PATCH",
    headers: {
      Authorization: getCookie("accessToken"),
    },
  });
}

export function postingUserAdRequest(id: number) {
  return request(`/account/announcement/index.php?id=${id}&action=extend`, {
    method: "PATCH",
    headers: {
      Authorization: getCookie("accessToken"),
    },
  });
}

export async function getCartRequest() {
  return await request(`/account/basket/index.php`, {
    method: "GET",
    headers: {
      Authorization: getCookie("accessToken"),
    },
  });
}

export async function getFavouritesRequest() {
  return await request(`/account/update_favorites/`, {
    method: "GET",
    headers: {
      Authorization: getCookie("accessToken"),
    },
  });
}
