import { removeCookie, setCookie } from "./cookie";

type TTokens = (refreshToken: string, accessToken: string) => void;

export const saveTokens: TTokens = (accessToken, refreshToken) => {
  setCookie("accessToken", accessToken);
  setCookie("refreshToken", refreshToken);
};

export const clearTokens: any = () => {
  removeCookie("accessToken");
  removeCookie("refreshToken");
};
