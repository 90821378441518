import { request } from "../functions/request";

export const getQuestionsCard = async (id: string | number) => {
  return request(`/question_answer/?id=${id}`, {
    headers: {
      Cookie: "wsr_nocache=1",
    },
    next: { revalidate: 0 },
  });
};
export const likeAnswerRequest = async (
  id: string | number,
  answerId: number | string
) => {
  return request(`/question_answer/?id=${id}&liked=${answerId}`);
};
export const dislikeAnswerRequest = async (
  id: string | number,
  answerId: number | string
) => {
  return request(`/question_answer/?id=${id}&dislaked=${answerId}`);
};

export const postAnswerRequest = async (
  cardId: string | number,
  answerId: string | number,
  answer: string
) => {
  const formData = new FormData();
  formData.append("text", answer);
  return request(`/question_answer/?id=${cardId}&answer_to=${answerId}`, {
    method: "POST",
    body: formData,
  });
};
export const postNewQuestionRequest = async (
  id: string | number,
  question: string
) => {
  const formData = new FormData();
  formData.append("text", question);
  return request(`/question_answer/?id=${id}`, {
    method: "POST",
    body: formData,
  });
};

export const getQuestionLikes = async (id: string | number) => {
  return request(`/question_answer/info.php?id=${id}`, {
    headers: {
      Cookie: "wsr_nocache=1",
    },
  });
};
