import { getCookie } from "../functions/cookie";
import { request } from "../functions/request";

export const refreshTokenRequest = () => {
  const formData = new FormData();
  formData.append("refresh_token", getCookie("refreshToken"));
  return request(`/auth/refresh_token.php`, {
    method: "POST",
    body: formData,
  });
};
