import { getCookie } from "../functions/cookie";
import { request } from "../functions/request";

export const loginTelRequest = (tel: number) => {
  return request(`/auth/index.php?phone=${tel}`, {
    method: "POST",
    headers: {
      "X-Device-Id": getCookie("deviceId"),
    },
  });
};

export const registerRequest = (email: string) => {
  return request(`/auth/index.php?email=${email}`, {
    method: "POST",
    headers: {
      "X-Device-Id": getCookie("deviceId"),
    },
  });
};

export const sendCodeRequest = (code: number, data: any) => {
  return request(`/auth/index.php?code=${code}`, {
    method: "POST",
    headers: {
      "X-Device-Id": getCookie("deviceId"),
    },
    body: data,
  });
};

export const sendCodeEmailRequest = (code: number, data: any) => {
  return request(`/auth/mail.php?code=${code}`, {
    method: "POST",
    headers: {
      "X-Device-Id": getCookie("deviceId"),
    },
    body: data,
  });
};

export const loginEmailRequest = (email: string) => {
  return request(`/auth/mail.php?email=${email}`, {
    method: "POST",
    headers: {
      "X-Device-Id": getCookie("deviceId"),
    },
  });
};

export const authVkRequest = (token: string, uuid: string, data: any) => {
  return request(`/auth/vk/index.php?uuid=${uuid}&token=${token}`, {
    method: "POST",
    body: data,
  });
};

export const connectVkRequest = (token: string, uuid: string) => {
  return request(
    `/account/social/index.php?set=vk&token=${token}&uuid=${uuid}`,
    {
      method: "GET",
    }
  );
};

export const disconnectVkRequest = () => {
  return request(`/account/social/index.php?unset=vk`, {
    method: "GET",
  });
};

export const authYandexRequest = (code: string, data: any) => {
  return request(`/auth/yandex/index.php?code=${code}`, {
    method: "POST",
    body: data,
  });
};
export const connectYandexRequest = (code: string) => {
  return request(`/account/social/index.php?set=yandex&code=${code}`, {
    method: "GET",
  });
};

export const disconnectYandexRequest = () => {
  return request(`/account/social/index.php?unset=yandex`, {
    method: "GET",
  });
};

export const authMailruRequest = (code: string, data: any) => {
  const origin = document.location.origin;
  const uri = `${origin}/auth/mailru/`;
  return request(`/auth/mail/index.php?redirect_uri=${uri}&code=${code}`, {
    method: "POST",
    body: data,
  });
};

export const connectMailruRequest = (code: string) => {
  const origin = document.location.origin;
  const uri = `${origin}/auth/mailru/`;
  return request(
    `/account/social/index.php?set=mail&redirect_uri=${uri}&code=${code}`,
    {
      method: "GET",
    }
  );
};

export const disconnectMailruRequest = () => {
  return request(`/account/social/index.php?unset=mail`, {
    method: "GET",
  });
};

export const authOkRequest = (code: string, data: any) => {
  const origin = document.location.origin;
  const uri = `${origin}/auth/ok/`;
  return request(`/auth/ok/index.php?redirect_uri=${uri}&code=${code}`, {
    method: "POST",
    body: data,
  });
};

export const clearAuthRequest = () => {
  return request(`/auth/clear/index.php`, {
    method: "POST",
    headers: {
      "X-Device-Id": getCookie("deviceId"),
      Cookie: "wsr_nocache=1",
    },
    next: {
      revalidate: 0,
    },
  });
};

export const authQrRequest = (code?: string, data?: any) => {
  if (code) {
    return request(`/auth/qr.php?code=${code}`, {
      method: "POST",
      headers: {
        "X-Device-Id": getCookie("deviceId"),
      },
      body: data,
    });
  } else {
    return request(`/auth/qr.php`, {
      headers: {
        "X-Device-Id": getCookie("deviceId"),
        Cookie: "wsr_nocache=1",
      },
      next: {
        revalidate: 0,
      },
    });
  }
};
