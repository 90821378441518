import { getCookie } from "../functions/cookie"
import { request } from "../functions/request"

export const editUserInfo = (method: string, type: string, data: string) => {
  return request(`/account/edit_${method}` + `/?${type}=${data}`, {
    method: "GET",
    headers: {
      "X-Device-Id": getCookie("deviceId"),
    }
  })
}