import { refreshTokenRequest } from "../api/token";
import { saveTokens } from "./tokens";
import { clearUserInfo } from "@/services/redux/features/userSlice";
import { callMessage } from "./callMessage";

export const refreshToken = (afterRefresh) => (dispatch) => {
  return refreshTokenRequest().then((res) => {
    if (res.token) {
      saveTokens(res.token.access_token, res.token.refresh_token);
      dispatch(afterRefresh);
    } else {
      callMessage("Ошибка!", res.error_msg, false);
      dispatch(clearUserInfo());
    }
  });
};
